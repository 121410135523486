import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import Footer from 'src/components/Footer'
import Navbar from 'src/components/Navbar'
import { userContext } from 'src/context/UserContext'
import { url } from 'src/helpers'

function ForgetPasswordEmail() {
    const [email, setEmail] = useState('')
    const { setLoad } = useContext(userContext)

    async function submitLogin(e) {
        e.preventDefault()
        setLoad(true)
        const response = await fetch(url + 'forgot-password-email?email=' + email)

        if (response.ok == true) {
            const data = await response.json()

            if (data.status == 200) {
                setLoad(false)
            } else {
                setLoad(false)
            }
            toast.success(data?.message)
        } else {
            setLoad(false)
        }

    }

    return (
        <>
            <Navbar type="full" />
            <div className='login-page-div custom-main-section'>
                <div className='inner-box d-flex flex-column align-items-center'>
                    <div className='w-100 mb-2'>
                        <form onSubmit={(e) => submitLogin(e)}>
                            <input type="email" className="form-control form-control-lg mt-2" required value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Address' />
                            <div className='w-100 d-flex justify-content-center align-items-center'>
                                <button type='submit' className='btn btn-success text-white mt-4'>Reset Passowrd</button>
                            </div>
                        </form>
                    </div>
                    <a href='/signup'>Don't have a account?. Create a new account</a>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ForgetPasswordEmail