import React, { Component, useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './screens/Home';
import './scss/style.scss';
import './App.scss';
import TrackShipment from './screens/TrackShipment';
import LoginPage from './screens/LoginPage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Signup from './screens/Signup';
import TermsOfServices from './screens/Terms/TermsOfServices';
import Privacy from './screens/Terms/Privacy';
import DriverPrivacy from './screens/Terms/DriverPrivacy';
import ShipperTerms from './screens/Terms/ShipperTerms';
import TransporterTerms from './screens/Terms/TransporterTerms';
import VerifyAccount from './screens/VerifyAccount';
import ForgetPasswordEmail from './screens/ForgetPassword/ForgetPasswordEmail';
import ForgetPassword from './screens/ForgetPassword/ForgetPassword';
import { userContext } from './context/UserContext';
import Loader from './components/Loader';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

function App() {
  const {load} = useContext(userContext)
  return (
    <BrowserRouter>
      {load && <Loader /> }
      <ToastContainer />
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path='/' name='Home' render={props => <Home {...props} />} />
          <Route exact path='/track-shipment' name='Track Shipment' render={props => <TrackShipment {...props} />} />
          <Route exact path='/login' name='Login' render={props => <LoginPage {...props} />} />
          <Route exact path='/verify' name='Verify' render={props => <VerifyAccount {...props} />} />
          <Route exact path='/ForgetPasswordEmail' name='ForgetPasswordEmail' render={props => <ForgetPasswordEmail {...props} />} />
          <Route exact path='/reset' name='ForgetPassword' render={props => <ForgetPassword {...props} />} />
          <Route exact path='/signup' name='Signup' render={props => <Signup {...props} />} />
          <Route exact path='/terms-of-services' name='terms-of-services' render={props => <TermsOfServices {...props} />} />
          <Route exact path='/Privacy' name='Privacy' render={props => <Privacy {...props} />} />
          <Route exact path='/DriverPrivacy' name='DriverPrivacy' render={props => <DriverPrivacy {...props} />} />
          <Route exact path='/ShipperTerms' name='ShipperTerms' render={props => <ShipperTerms {...props} />} />
          <Route exact path='/TransporterTerms' name='TransporterTerms' render={props => <TransporterTerms {...props} />} />

          <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App;
