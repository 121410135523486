import React, { useContext, useEffect, useState } from 'react'
import Footer from 'src/components/Footer'
import Navbar from 'src/components/Navbar'
import Lottie from "lottie-react";
import groovyWalkAnimation from '../loader.json'
import { useLocation } from 'react-router-dom';
import { url } from 'src/helpers';
import { userContext } from 'src/context/UserContext';

function VerifyAccount() {
    const {setLoad} = useContext(userContext)
    const [message,setMessage] = useState('')
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const token = useQuery().get('token');
    const [loader,setLoader] = useState(false)

    useEffect(() => {
        if (token) {
            async function activateAccount(){
                setLoader(true)
                const response = await fetch(url + 'activate-account?token=' + token)
                if (response.ok == true){
                    const data = await response.json()

                    if (data.status == 200){
                        setLoader(false)
                        setMessage(data?.message)
                        alert(data?.message)
                    }else{
                        setLoader(false)
                        setMessage(data?.message)
                        alert(data?.message)
                    }
                }else{
                    setLoader(false)
                }

            }

            activateAccount()
        }
    }, [token])
    return (
        <>
            <Navbar type="full" />
            <div className='login-page-div custom-main-section'>
                <div className='inner-box d-flex flex-column align-items-center'>
                    <h5 className='text-center'>
                        {message?.length > 0 ? message : 'Please hold On. Do not close this page we are verifying your account.'}</h5>
                    {loader && <Lottie animationData={groovyWalkAnimation} loop={true} />}
                    {!loader && <a href='/login' style={{textDecoration : 'none'}} className='btn btn-success'>
                        Login Your Account
                    </a>}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default VerifyAccount