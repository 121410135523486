import React from 'react'

function Loader() {
  return (
    <div className='loader-div'>
      <img src="/assets/images/loader.gif" alt="" />
    </div>
  )
}

export default Loader