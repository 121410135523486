import React, { useContext, useState } from 'react'
import Footer from 'src/components/Footer'
import Navbar from 'src/components/Navbar'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { url } from 'src/helpers';
import { userContext } from 'src/context/UserContext';

function ForgetPassword() {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const token = useQuery().get('token');

    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')
    const { setLoad } = useContext(userContext)

    async function submitLogin(e) {
        e.preventDefault()

        if (password == cpassword) {
            setLoad(true)

            const formData = new FormData()

            formData.append('token',token)
            formData.append('password',password)

            const response = await fetch(url + 'forgot-password',{
                method : 'POST',
                body : formData
            })

            if (response.ok == true) {
                const data = await response.json()

                if (data.status == 200) {
                    setLoad(false)
                    alert(data.message)
                    window.location = window.location.origin + '/login'
                } else {
                    setLoad(false)
                    alert(data?.message)
                }
            } else {
                setLoad(false)
            }
        }else{
            toast.error("Password and confirm password doesn't match")
        }

    }

    return (
        <>
            <Navbar type="full" />
            <div className='login-page-div custom-main-section'>
                <div className='inner-box d-flex flex-column align-items-center'>
                    <div className='w-100 mb-2'>
                        <form onSubmit={(e) => submitLogin(e)}>
                            <input type="password" className="form-control form-control-lg mt-2" required value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' />
                            <input type="password" className="form-control form-control-lg mt-2" required value={cpassword} onChange={e => setCPassword(e.target.value)} placeholder='Confirm Password' />
                            <div className='w-100 d-flex justify-content-end '>
                                <button type='submit' className='btn btn-success text-white mt-4'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ForgetPassword